import _get from 'lodash/get';
import _set from 'lodash/set';

import { fetchJSON } from '@bonnet/next/fetch';
import { BonnetModule } from '@bonnet/next/modules';

import { translateKeys } from '@atc/bonnet-parameters';
import { createAction, createSelector, Duck, DuckSelector } from '@atc/modular-redux';

import { hydrateInventory } from 'axl-ducks';

import getAlphaOwnerWebsiteLink from '@/utilities/getAlphaOwnerWebsiteLink';

import { inventoryDuck, ownersDuck } from '@/ducks';

export default function AlphaModuleCreator() {
    const alphaModule = new BonnetModule({
        duck: new Duck({
            store: 'alpha',
            consts: {},
            types: [
                'ADD_LISTING',
                'DELETE_LISTING',
                'IS_QUICK_SAVED',
                'SET_LOADING',
                'UPDATE_ALPHA',
                'CLEAR_ALPHA',
            ],
            initialState: {
                alphaInventoryVisible: true,
                dealerDetailUrl: '',
                defaultTabIndex: 0,
                listings: [],
                loading: false,
                owner: {},
                similarVehiclesCount: 0,
                showcaseError: false,
                title: '',
            },
            reducer: (state, action, { initialState, types }) => {

                switch (action.type) {

                    case types.CLEAR_ALPHA: {
                        return initialState;
                    }

                    case types.ADD_LISTING: {
                        return { ...state };
                    }

                    case types.UPDATE_ALPHA: {
                        return {
                            ...state,
                            ...action.payload,
                            loading: false,
                        };
                    }

                    case types.SET_LOADING: {
                        return {
                            ...state,
                            loading: true,
                        };
                    }

                    default:
                        return state;
                }
            },
            creators: ({ types }) => ({
                ADD_LISTING: createAction(types.ADD_LISTING),
                UPDATE_ALPHA: createAction(types.UPDATE_ALPHA),
                SET_LOADING: createAction(types.SET_LOADING),
                CLEAR_ALPHA: createAction(types.CLEAR_ALPHA),
            }),
            selectors: (duck) => ({
                getState: new DuckSelector((selectors) => createSelector(
                    selectors.getDuckState,
                    (localState) => localState,
                )),
                isListingAlpha: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).defaultTabIndex === 0),
                isAlphaMarket: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).alphaMarket),
                isLoading: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).loading),
                hasActiveResults: new DuckSelector((selectors) => (state) => _get(selectors.getDuckState(state), 'listings.length', 0) !== 0),
                hasAlphaListings: new DuckSelector((selectors) => createSelector(
                    selectors.getDuckState,
                    (localState) => _get(localState, 'defaultTabIndex') !== 1 && _get(localState, 'listings.length') > 0,
                )),
                getSimilarListingsCount: new DuckSelector((selectors) => (state) => _get(selectors.getDuckState(state), 'similarListingsCount', 0)),
                getActiveResults: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).listings),
                getActiveInventory: new DuckSelector((selectors) => createSelector(
                    selectors.getActiveResults,
                    inventoryDuck.selectors.getInventory,
                    ownersDuck.selectors.getOwners,
                    (state) => _get(state, 'brand', 'atc'),
                    (activeResults, inventory, owners, brand) => activeResults.map((id) => hydrateInventory(id, inventory, owners, brand))
                )),
                getOwner: new DuckSelector((selectors) => createSelector(
                    (state) => _get(selectors.getDuckState(state), 'owner'),
                    ownersDuck.selectors.getOwners,
                    (ownerId, owners) => {
                        const alphaOwner = {
                            ..._get(owners, [ownerId], {}),
                        };
                        // TODO is there a better way to handle this?
                        _set(alphaOwner, 'website.href', getAlphaOwnerWebsiteLink(alphaOwner));
                        return alphaOwner;
                    }
                )),
            }),
        }),
        ctxMiddleware: ({
            duck: alphaDuck,
        }) => (ctx) => {
            const {
                disable_alpha: [disableAlpha],
                alpha_market: [enableAlphaMarket],
            } = ctx.useFeatures([
                'disable_alpha',
                'alpha_market',
            ]);

            const lscSearch = translateKeys(ctx.data.requestOptions.optionalRequestLSC.query, { target: 'lsc' });
            const alphaRequestOptions = {
                ...ctx.data.requestOptions.optionalRequestLSC,
                query: lscSearch,
            };

            if (!disableAlpha) {
                ctx.store.dispatch(alphaDuck.creators.SET_LOADING());
                if (enableAlphaMarket) {
                    alphaRequestOptions.query.alphaMarket = true;
                }

                // assign the fetcher for alpha data
                ctx.fetchCompositeJSON.addFetch('alpha', fetchJSON('/rest/lsc/alpha/base', {
                    ...alphaRequestOptions,
                    circuitBreaker: {
                        ...alphaRequestOptions.circuitBreaker,
                        id: 'alpha_base',
                    },
                }));

                // create a deferred action to dispatch the response
                ctx.batchDispatch.createActions((deferredCtx) => {

                    const alphaActionCreators = [];

                    // extract the alpha response
                    const { alpha = {} } = deferredCtx.data;

                    const {
                        // TODO: BONNET - dont call things beans!
                        ownerBean: owner = {},
                        alphaShowcase: alphaListings = [],
                        ...alphaRest
                    } = alpha;

                    if (owner) {

                        // add alpha inventory to global store
                        if (alphaListings.length > 0) {
                            alphaActionCreators.push(inventoryDuck.creators.addInventory(alphaListings));
                        }

                        // add alpha owner to the global owner store
                        alphaActionCreators.push(ownersDuck.creators.addOwners([owner]));

                        // add the alpha data
                        alphaActionCreators.push(alphaDuck.creators.UPDATE_ALPHA({
                            ...alphaRest,
                            alphaInventoryVisible: true,
                            listings: alphaListings.map((listing) => listing.id),
                            owner: owner.id,
                            similarListingsCount: alpha.similarVehiclesCount,
                        }));

                    } else {
                        // clear the alpha store
                        alphaActionCreators.push(alphaDuck.creators.CLEAR_ALPHA());
                    }

                    // return the array of action creators to be dispatched
                    return alphaActionCreators;

                });
            }
        },
    });

    return alphaModule;
}
