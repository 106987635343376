import { brands } from 'reaxl-brand';

import { inventorySave } from '@/analytics/srpAnalyticsHandlers';

import {
    authDuck,
    savedInventoryDuck,
} from '@/ducks';

import importAuthUtilityBundle from './importAuthUtilityBundle';

const valuePropositionHeading = 'Save More Cars with a Free Account';
const valuePropositionBody = 'Access 3 saved vehicles without signup. Want more? Create an account to track price changes, saved cars across devices, and more.';

const fireAnalytics = ({ sendClick, event, analyticsData, analyticsHandler = inventorySave }) => {
    sendClick(analyticsHandler, event, {
        ...analyticsData,
    });
};

export default function saveListing({
    event,
    context = '',
    listing = {},
    wasSaved,
    sendClick,
    saveType = {},
    wasSticky,
    parentId,
}) {

    // The event must be 'persisted' to be referenced in asynchronous functions like to one below; otherwise its
    // properties may be overwritten by React before the asynchronous function has a chance to read them.
    // See https://reactjs.org/docs/events.html
    if (event && event.persist) {
        event.persist();
    }

    return async (dispatch, getState) => {

        const spotlightUrgencyDrivers = {
            incentive: 'incentives',
            reduced: 'price-reduced',
            newlylisted: 'newly-listed',
            numviewed: 'number-viewed',
        };

        let suffix = '';
        let par = 'v_lstg';
        let inheritPageData = true;
        if (saveType.alpha) {
            suffix = '_a';
            par = parentId;
        } else if (saveType.accelerate) {
            par = '';
        } else if (saveType.carousel) {
            par = 'sim_v_lstg';
            inheritPageData = false;
        } else if (listing.tier && Object.keys(listing.tier).length) {
            switch (listing.tier.name) {
                case 'peekaboo':
                    par = spotlightUrgencyDrivers[listing.tier.urgencyDriverType];
                    break;
                case 'spotlight':
                    par = `spv_lstg_${(listing.tier.index + 1)}`;
                    break;
                case 'supplemental':
                    par = `sup_v_lstg_${(listing.tier.index + 1)}`;
                    break;
                case 'boost':
                    par = `ncb_${(listing.tier.index + 1)}`;
                    break;
                case 'ime':
                    par = `ime_${(listing.tier.index + 1)}`;
                    break;
                default:
                    par = `v_lstg_${(listing.tier.index + 1)}`;
            }
        }
        const isLoggedIn = authDuck.selectors.isLoggedIn(getState());
        const hasMaxQuickSaveItems = savedInventoryDuck.selectors.hasMaxQuickSaveItems(getState());
        const isKBB = getState()?.brand === brands.KBB_BRAND;
        // This should be called only once if flag is enabled, otherwise called below for both events
        fireAnalytics({
            sendClick,
            event,
            analyticsData: {
                inventoryId: listing.id,
                wasSaved,
                suffix,
                par,
                inheritPageData,
                wasSticky,
                context,
            },
        });

        if (!isLoggedIn && !wasSaved && hasMaxQuickSaveItems) {
            const { showAuthModal, once } = await importAuthUtilityBundle();
            once('auth.signin', () => {
                if (isKBB) {
                    // set isLoggedIn so that kbb header catches up
                    dispatch(authDuck.creators.setLoggedIn());
                    dispatch(savedInventoryDuck.creators.saveInventoryItem(listing));
                }
                fireAnalytics({
                    sendClick,
                    event,
                    analyticsData: {
                        inventoryId: listing.id,
                        wasSaved,
                        suffix,
                        par,
                        inheritPageData,
                        wasSticky,
                        context,
                    },
                });
            });
            once('auth.close', (payload = {}) => {
                const { action } = payload;
                if (action === 'user-closed' && isKBB) {
                    dispatch(savedInventoryDuck.creators.unsaveInventoryItem(listing));
                }
            });

            showAuthModal(isKBB ? {} : { valuePropositionHeading, valuePropositionBody });

            // Use a raw action instead of a creator to avoid modifying localStorage or
            // attempting saving on the server until the user is signed in.
            // In the meantime, the save (heart) glyph will be filled.
            if (isKBB) {
                dispatch({
                    type: savedInventoryDuck.types.ADD_SAVED_INVENTORY_ITEM,
                    payload: listing.id,
                });
            }
        } else if (wasSaved) {
            await dispatch(savedInventoryDuck.creators.unsaveInventoryItem(listing));
        } else {
            await dispatch(savedInventoryDuck.creators.saveInventoryItem(listing));
        }
    };
}

export function saveAlphaListing(data) {
    return saveListing({ saveType: { alpha: true }, ...data });
}

export function saveBAPListing(data) {
    return saveListing({ saveType: { bap: true }, ...data });
}

export function saveVDPListing(data) {
    return saveListing({ saveType: { vdp: true }, ...data });
}

export function saveAccelerateListing(data) {
    return saveListing({ saveType: { accelerate: true }, ...data });
}

export function saveVDPCarouselListing(data) {
    return saveListing({ saveType: { carousel: true }, ...data });
}
